const getApiUrl = () => {
    if (typeof window === 'undefined') {
      return ''; // Return empty string or handle SSR case if needed
    }
  
    // eslint-disable-next-line prefer-destructuring
    const host = window.location.hostname;
  
    if (host === 'onboarding.zimtra.ky' || host === 'onboarding.zimtra.com') {
      return 'https://traderapi.at-hub.com/v2/api'; // Production URL
    }
    if (host === 'cloud-stage.onboarding.zimtra.com') {
      return 'https://stage.zimtra.ky/api'; // Stage URL
    }
    if (host === 'dev.onboarding.zimtra.at-hub.com' || host.includes('localhost')) {
      return 'https://dev.zimtra.at-hub.com/api'; // Development URL
    }
  
    return ''; // Default or handle unknown case
  };
  
  export default getApiUrl;
  